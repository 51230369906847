import * as _ from 'lodash';
import React from 'react';

export function getVariable(scope: string, name: string, fallback = '') {
  return _.get(window, `swsm.${scope}.${name}`, fallback);
}
export function getUrl() {
  return window.location.host;
}

export function getApiUrl() {
  const env = getUrl();
  if (env === 'localhost:3000' || env === 'dev.sponsor.getwaterfit.co.uk') {
    return `https://api.dev.getwaterfit.co.uk/`;
  } else if (env === 'stage.sponsor.getwaterfit.co.uk') {
    return `https://api.stage.getwaterfit.co.uk/`;
  } else if (env === 'stage.sponsor.a4we.getwaterfit.com') {
    return 'https://api.stage.a4we.getwaterfit.com/';
  } else if (env === 'stage.sponsor.smartwateradvice.getwaterfit.com') {
    return 'https://api.stage.smartwateradvice.getwaterfit.com/';
  } else if (env === 'sponsor.smartwateradvice.getwaterfit.com') {
    return 'https://api.smartwateradvice.getwaterfit.com/';
  } else if (env === 'sponsor.a4we.getwaterfit.com') {
    return 'https://api.a4we.getwaterfit.com/';
  }
  return `https://api.getwaterfit.co.uk/`;
}

export function getMicroServiceUrl(name: string, version = '1.0') {
  return `${getApiUrl()}${name}/api/${version}/`;
}

export function getEndpointURL(relativeURL: string) {
  return `${getApiUrl()}sponsor-portal/api/1.0/dashboard/${relativeURL}`;
}

export const useResponsivity = () => {
  const [isOnMobile, setIsOnMobile] = React.useState(true);
  React.useEffect(() => {
    /*Check the screen size on every render, prevent possible bugs by
        checking the screen size and setting the isOnMobile when necessary.
        */
    function handleResize() {
      if (window.innerWidth >= 800) {
        setIsOnMobile(false);
      } else {
        setIsOnMobile(true);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    //Cleanup the useEffect when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return isOnMobile;
};
